import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import menu from 'src/static/AboutPics/menu.png';
import close from 'src/static/AboutPics/cross.png';

import styles from './styles.css';

class Menu extends Component {
  container = React.createRef();

  button = React.createRef();

  static propTypes = {
    className: PropTypes.string,
  };

  state = {
    menuIsOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.button.current !== event.target) {
      this.setState({ menuIsOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState({ menuIsOpen: true });
  };

  closeMenu = () => {
    this.setState({ menuIsOpen: false });
  };

  render() {
    const { className } = this.props;
    const { menuIsOpen } = this.state;

    return (
      <div className={className}>
        <div className="menuBtnWrap">
          <div className="navbar">
            <div className="navHorizontal">
              <Link className="navbarItem" to="/">
                <span>Home</span>
              </Link>
              <Link className="navbarItem" to="/albums/current">
                <span>Last solo show</span>
              </Link>
              <Link className="navbarItem" to="/photographs">
                <span>Portfolio</span>
              </Link>
              <Link className="navbarItem" to="/collabs">
                <span>Collabs</span>
              </Link>
              <Link className="navbarItem" to="/projects">
                <span>Projects</span>
              </Link>
              <Link className="navbarItem" to="/about">
                <span>About</span>
              </Link>
              <Link className="navbarItem" to="/contact">
                <span>Contact</span>
              </Link>
            </div>
            <div
              onClick={this.toggleMenu}
              ref={this.button}
              className="menuButton">
              <img className="imageAbout" src={menu} alt="img" />
            </div>
            <div
              ref={this.container}
              className="dropdown-content"
              style={{ maxHeight: menuIsOpen ? '400px' : '0px' }}>
              <div className="closeButton">
                <img className="imageClose" src={close} alt="img" />
              </div>
              <Link to="/">
                <span>Home</span>
              </Link>
              <Link to="/albums/current">
                <span>Last solo show</span>
              </Link>
              <Link to="/photographs">
                <span>Portfolio</span>
              </Link>
              <Link to="/collabs">
                <span>Collabs</span>
              </Link>
              <Link to="/projects">
                <span>Projects</span>
              </Link>
              <Link to="/about">
                <span>About</span>
              </Link>
              <Link to="/contact">
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default styled(Menu)`
  ${styles}
`;
