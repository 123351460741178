import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Container from 'src/layout/components/Container';
import Menu from 'src/layout/components/Menu';

import styles from './styles';
import './styles.css';

const Header = ({ className }) => (
  <div className={className}>
    <Container>
      <div className="headerContent">
        <Menu />
        <h2 className="headerMain">
          <Link to="/">Lena Leiko</Link>
        </h2>
      </div>
    </Container>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  ${styles}
`;
