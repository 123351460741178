import React from 'react';

import './index.css';

export default () => {
  React.useEffect(() => {
    document.title = 'Lena Leiko Contact';
  }, []);

  return (
    <div className="aboutContent">
      <div className="aboutInfo">
        <div>
          <h3>Contact</h3>
          <p>
            <a href="mailto:lenaleikko@gmail.com">
              Email: lenaleikko@gmail.com
            </a>
          </p>
          <p>
            <a href="tel:+31-63-932-0135">Phone: +31 (63) 932-0135</a>
          </p>
          <p>
            <a
              href="https://www.instagram.com/lena.leiko/"
              target="_blank"
              rel="noopener noreferrer">
              Instagram: lena.leiko
            </a>
          </p>
        </div>
      </div>
      {/* <div className="aboutPicture">
      <img src={require('src/static/AboutPics/helen3.jpeg')} />
    </div> */}
    </div>
  );
};
