import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Collabs1 from 'src/static/Collabs/collab_00p.jpg';
import Layers from 'src/static/Layers/1.png';
import Irreversibility from 'src/static/Irreversibility/3p.jpg';
import icoStack from 'src/static/AboutPics/icoStack.png';

import './index.css';

export default () => {
  React.useEffect(() => {
    document.title = 'Lena Leiko Projects';
  }, []);

  const RequestPrice = img => (
    <a
      href={`mailto:lenaleikko@gmail.com?subject=${'Collabs'}&body=Hello,\n\nI would like to know the details and the price of this artwork
    \n\n https://lenaleiko.com${img}`}
      className="prc">
      contact the artist
    </a>
  );
  // Hello,

  // I would like to know the details and the price of this artwork и ссылка 🔗
  return (
    <div className="wrapper-pho">
      <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/Collabs">
          <img className="itemPhotoC" src={Collabs1} alt="Layers" />
        </Link>
        <div className="paintinfocontainerC">
          <div className="paintinfoHeaderC">
            <div className="labelPhoto"> </div>
            <div className="rowPriceC">{RequestPrice(Collabs1)}</div>
          </div>
          <div className="paintinfo">fine art paper</div>
          <div className="paintinfo">aluminium frame</div>
          <div className="paintinfo">museum glass </div>
          <div className="labelPhoto">750€ incl. VAT</div>
        </div>
      </div>
      {/* <Link to="/albums/Irreversibility" className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <img
          className="itemPhoto"
          src={Irreversibility}
          alt="Irreversibility"
        />
        <div className="labelPhoto">Irreversibility</div>
      </Link> */}
      {/* <Link to="/albums/Origin" className="itemPhotoWrapper">
      <img className="itemPhoto" src={Origin} alt="Origin" />
      <div className="labelPhoto">{'Origin'}</div>
    </Link> */}
    </div>
  );
};
