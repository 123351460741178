import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import JSON from 'src/albums.json';

import './styles.css';

export default class Main extends Component {
  state = {
    active: null,
  };

  componentDidMount() {
    document.title = 'Lena Leiko Prints';
  }

  onToggleState = active =>
    this.setState({
      active,
    });

  render() {
    const { active } = this.state;

    return (
      <div className="container">
        {JSON.albums.map((al, idx) => (
          <Link key={al.name} to={`/albums/${al.name}`}>
            <div
              onMouseOver={() => this.onToggleState(idx)}
              onFocus={() => this.onToggleState(null)}
              onMouseLeave={() => this.onToggleState(null)}
              className="link"
              style={{
                backgroundImage: `url(${require(`src/static/${al.cover}`)})`, // eslint-disable-line
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
              }}>
              {active === idx && (
                <div className="albumName">
                  <div className="albumBlur">
                    <span>{al.name}</span>
                  </div>
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
    );
  }
}
