import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from 'src/input/components/Popup';

import styles from './styles';
import './index.css';

const getClassName = (index, ratio, album) => {
  if (album.name === 'Layers' || album.name === 'Paper') {
    return 'item1';
  }
  console.log('ratio', ratio);

  if (
    (album.name === 'Origin' && index === 0) ||
    (album.name === 'Origin' && index === 1) ||
    (album.name === 'Origin' && index === 2)
  ) {
    return 'item4';
  }
  if (album.type === 'Paintings') return 'item';

  if (index % 3 === 0 && ratio < 1 && album.photos.length > 10) {
    return 'item2';
  } else if (index % 1 === 0 && ratio < 1 && album.photos.length < 6) {
    return 'item2';
  } else if (index % 8 === 0 && ratio < 1 && album.photos.length < 10) {
    return 'item2';
  }
  return ratio > 1 && album.photos.length === 1
    ? 'item8'
    : ratio == 1 && album.photos.length === 1
    ? 'item7'
    : ratio > 1
    ? 'item1'
    : 'item2';
};

class Album extends Component {
  state = {
    currentIdx: 0,
    isOpen: false,
    loadedImagesIdxs: [],
    imagesWithRatio: [],
  };

  async componentWillMount() {
    this.getImages();

    document.title = 'Lena Leiko Album';
  }

  getImages = async () => {
    const { album } = this.props;
    const imagesWithRatio = await Promise.all(
      album.photos.map(
        (image, idx) =>
          new Promise(resolve => {
            const img = new Image();

            img.onload = () => {
              const { height, width } = img;
              const ratio = width / height;

              resolve({
                image,
                ratio,
                className: getClassName(idx, ratio, album),
              });
            };

            img.src = require(`src/static/${image}`); // eslint-disable-line
          }),
      ),
    );

    this.setState({
      imagesWithRatio,
    });
  };

  componentDidUpdate({ location: { pathname } = {} }) {
    if (pathname !== this.props.location.pathname) {
      this.setState(
        {
          // eslint-disable-line
          loadedImagesIdxs: [],
          imagesWithRatio: [],
        },
        this.getImages,
      );
    }
  }

  renderImage = (img, idx, info, onPressImage) => {
    const { loadedImagesIdxs } = this.state;
    const hasLoaded = loadedImagesIdxs.includes(idx);

    return (
      <Fragment>
        {!hasLoaded && (
          <div className="loading">{/* eslint-disable-line */}</div>
        )}

        <img
          onLoad={() =>
            this.setState(prevState => ({
              loadedImagesIdxs: [...prevState.loadedImagesIdxs, idx],
            }))
          }
          onClick={onPressImage}
          alt={img.image}
          style={!hasLoaded ? { display: 'none' } : {}}
          className="imageItem"
          src={require(`src/static/${img.image}`)} // eslint-disable-line
        />

        <div className="photoinfo">{info}</div>
      </Fragment>
    );
  };

  render() {
    const { album = { photos: [] } } = this.props;
    const { currentIdx, isOpen, imagesWithRatio } = this.state;

    if (!imagesWithRatio.length) {
      return (
        <div className="loading">
          <img src={require('src/static/AboutPics/loading.gif')} />
          {/* eslint-disable-line */}
        </div>
      );
    }
    if (album.type === 'gallery') {
      return (
        <div className="contentWrapper">
          <div className="wrapContainer">
            <div className="wrapper album">
              {imagesWithRatio.map((e, i) => (
                <div className={`albumItem ${e.className}`} key={i}>
                  {this.renderImage(e, i)}
                  <div
                    className="infoWrap"
                    onClick={() =>
                      this.setState({ currentIdx: i, isOpen: true })
                    }></div>
                </div>
              ))}
              {isOpen && (
                <Popup
                  isOpen={isOpen}
                  currentIdx={currentIdx}
                  images={album.photos}
                  closePopup={() => this.setState({ isOpen: false })}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={
          album.type === 'Paintings' ? 'wrapper-painting' : 'wrapper-photo'
        }>
        {album.description ? (
          <div className="photoDescriptionContainer">
            {album.name === 'current' || album.name === 'Collabs' ? null : (
              <div className="photoDescriptHeader">{album.name}</div>
            )}
            <div className="photoDescript">{album.description || ''}</div>
          </div>
        ) : null}
        {/* album.photos.length === 1 ? 'imageLandscapeItem' : */}
        <div
          className={
            album.type === 'Paintings' ? 'content-painting' : 'content-photo'
          }>
          <div className={album.type === 'Paintings' ? 'paints' : ''}>
            {imagesWithRatio.map((e, i) => (
              <div
                // onClick={() => this.setState({ currentIdx: i, isOpen: true })}
                className={
                  album.type === 'Paintings'
                    ? 'albumItemPainting'
                    : `albumItem ${e.className}`
                }
                key={i}>
                {this.renderImage(
                  e,
                  i,
                  album.type === 'Photo' ? album.photoinfo[e.image] : null,
                  () => this.setState({ currentIdx: i, isOpen: true }),
                )}

                {/* <div className="photoinfo">{album.photoinfo[e.image]}</div> */}
                {/* {album.photoinfo ? <p>{album.photoinfo[e.image]}</p> : null} */}
                {album.info ? (
                  <div className="paintinfocontainer">
                    <div className="paintinfoHeader">
                      {album.info[e.image] ? album.info[e.image].name : ' '}
                    </div>
                    <div className="paintinfo">
                      {album.info[e.image]
                        ? album.info[e.image].technique
                        : ' '}
                    </div>
                    <div className="infoBottomContainer">
                      <div className="paintinfo">
                        {album.info[e.image] ? album.info[e.image].size : ''}
                      </div>
                      {album.info[e.image] ? (
                        <a
                          href={
                            album.info[e.image].status === 'availible'
                              ? `mailto:lenaleikko@gmail.com?subject=${
                                  album.info[e.image].name
                                }&body=Hello, I would like to ask about the price of the painting.`
                              : null
                          }
                          className="prc">
                          {album.info[e.image].status === 'availible'
                            ? 'request the price'
                            : album.info[e.image].status === 'sold'
                            ? 'out of stock'
                            : ''}
                        </a>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* <div
              className="infoWrap"
              onClick={() =>
                this.setState({ currentIdx: i, isOpen: true })
              }></div> */}
              </div>
            ))}
          </div>
        </div>
        {isOpen && (
          <Popup
            isOpen={isOpen}
            currentIdx={currentIdx}
            images={album.photos}
            photoinfo={album.photoinfo}
            closePopup={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}

Album.propTypes = {
  album: PropTypes.object,
  location: PropTypes.object,
};

export default styled(Album)`
  ${styles}
`;
