import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './splash.css';

export default function Splash() {
  const googleMapsLink = 'https://maps.app.goo.gl/RE5c9HKibjpiD7ti8';

  return (
    <div className="splashContainer">
      <div className="eventContainer">
        <Link className="navbarItem" to="/albums/current">
          <div className="bgText">
            <p className="eventHeader">
              last solo show
              <br />
            </p>
          </div>
        </Link>
        {/* <div className="eventHeader">March 22-28*</div>
        <div className="eventText">
          The gallery is open every day from 12:00 till 18:00
        </div>
        <div className="eventText">EM Studio Gallery</div>
        <div>
          <br />

          <a
            className="eventText"
            href={googleMapsLink}
            target="_blank"
            rel="noopener noreferrer">
            📍 Witte de Withstraat 2, 1057 XV Amsterdam
          </a>
        </div> */}
      </div>
      {/* <img className="bg1" src={require(`src/static/Splash/Layer 1.png`)} />
      <img className="bg2" src={require(`src/static/Splash/worm 1.png`)} />
      <img className="bg4" src={require(`src/static/Splash/worm 01.png`)} />
      <img className="bg5" src={require(`src/static/Splash/worm 02.png`)} />
      <img className="bg6" src={require(`src/static/Splash/worm 03.png`)} />
      <img className="bg7" src={require(`src/static/Splash/worm 04.png`)} />
      <img className="bg8" src={require(`src/static/Splash/worm 05.png`)} />
      <img className="bg9" src={require(`src/static/Splash/worm 06.png`)} /> */}
    </div>
  );
}
//
