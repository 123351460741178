const color = {
  primary: '#333',
  primaryInverted: '#ddd',
};

const font = {
  primary: 'Raleway, sans-serif',
};

export default {
  color,
  font,
  globalWidth: 960,
};
