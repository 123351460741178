import styled from 'styled-components';

export const Wrapper = styled.footer`
  margin-top: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  // background-color: #e4e4e4;
`;

export const SocialLinks = styled.span`
  white-space: nowrap;
`;

export const Link = styled.a`
  padding-left: 12px;

  > img {
    width: 20px;
    height: 20px;
  }
`;
