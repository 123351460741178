import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Wrapper from 'src/layout/components/Wrapper';
import Header from 'src/layout/components/Header';
import Body from 'src/layout/components/Body';
import Footer from 'src/layout/components/Footer';

import './index.css';

export default class Layout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;

    return (
      <Wrapper>
        <Header />
        <Body>{children}</Body>
        <Footer />
        {/* <p className="menuButtonBottom">MENU</p> */}
      </Wrapper>
    );
  }
}
