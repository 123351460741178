import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, SocialLinks, Link, ScrollToTop } from './styles';

const Footer = () => (
  <Wrapper>
    <a href="https://lenaleiko.com/">©Lena Leiko 2024</a>
  </Wrapper>
);

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
