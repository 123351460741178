import Main from './Main';
import Album from './Album';
import About from './About';
import Prints from './Prints';
import Contact from './Contact';
import Splash from './Splash';
import Photographs from './Photographs';
import Collabs from './Collabs';

export default (root = '') => [
  {
    path: `${root}/`,
    component: Splash,
    exact: true,
  },
  {
    path: `${root}/paintings`,
    component: Main,
    exact: true,
  },
  {
    path: `${root}/collabs`,
    component: Collabs,
    exact: true,
  },
  {
    path: `${root}/photographs`,
    component: Photographs,
    exact: true,
  },
  // {
  //   path: `${root}/photographs`,
  //   component: Photographs,
  //   exact: true,
  // },
  {
    path: `${root}/projects`,
    component: Main,
    exact: true,
  },
  {
    path: `${root}/about`,
    component: About,
    exact: true,
  },
  {
    path: `${root}/contact`,
    component: Contact,
    exact: true,
  },
  {
    path: `${root}/prints`,
    component: Prints,
    exact: true,
  },
  {
    path: `${root}/albums/:name`,
    component: Album,
    exact: true,
  },
];
