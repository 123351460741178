/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/components/App';
import './theme/css/critical.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// lazy loading of secondary styles
import('./theme/css/rest.css');

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB4QQVAbiJZrhiChMUy4CGMbwGOnU44yhE',
  authDomain: 'leiko-a3f0d.firebaseapp.com',
  projectId: 'leiko-a3f0d',
  storageBucket: 'leiko-a3f0d.appspot.com',
  messagingSenderId: '57423664760',
  appId: '1:57423664760:web:17f65411f75af330c87f8d',
  measurementId: 'G-DBKKE8YS9K',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

if (module.hot) {
  module.hot.accept();
}
