import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './styles';

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBottom: false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        this.setState({ isBottom: true });
      } else {
        this.setState({ isBottom: false });
      }
    });
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={className}>
        {children}
        {this.state.isBottom ? (
          <div
            className="toTop"
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }>
            <img
              className="imageUp"
              src={require('src/static/test/arrowUp2.png')}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default styled(Wrapper)`
  ${styles}
`;
