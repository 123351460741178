import React from 'react';
import photo from 'src/static/AboutPics/Lena.jpg';
import cv from 'src/static/main/CV_LenaLeiko.pdf';

import './index.css';

export default () => {
  React.useEffect(() => {
    document.title = 'Lena Leiko About';
  }, []);

  return (
    <div className="containerAbout">
      <div className="aboutContent">
        <div className="aboutScreenPicture">
          <img className="imageAbout" src={photo} alt="img" />
        </div>
        <div className="AboutText">
          <p>
            Lena Leiko is a self-taught artist born into a dynasty of artists in
            Russia. For the past seven years, she has lived and worked in
            Amsterdam. The turning point that became the impetus for the start
            of her independent artistic research was the death of her father.
            Faced with this traumatic experience, she moved away from working
            with models and turned to art to seek answers.
            <br />
            <p> </p>
            In her artistic practice, Lena explores the aesthetics of macro
            photography, tracing its inheritance of abstract thought and
            imagery. She points her lens at things that the human eye is trained
            not to notice. The textures and shapes of acrylic paint, foil,
            beads, glittery sand, and other visual objects that escape attention
            reveal a whole cosmos within them. Within these objects, one can
            discern completely new forms, unfamiliar to science, which quite
            likely exist in a boundless multiverse. They seem to hint at the
            existence of alternative laws of physics. Leiko is especially
            interested in how such macro images transform the logic of
            space-time coordinates, creating the illusion of linear, ordered
            movement when, in fact, this movement can be completely different in
            nature.
            <br />
            <p> </p>
            As an artist, Lena wonders about the essence and causes of the Big
            Bang. She is looking for its imprints in macro-structures that are
            so distant from human perception. Photography allows her to capture
            images of particles, giving her the opportunity to think and imagine
            the starting point of the universe's life in slow motion, in
            relativity, almost in temporal reversibility.
            <br />
            <p> </p>
            Lena's artistic research is driven by the idea of the
            interconnection of micro-particles, the inclusion of the smallest
            components of existence in events commensurate with humans yet at
            the same time incommensurable, visible on the scale of the universe.
            Life as humans know it, the life of galaxies and black holes on a
            micro-scale, seems to unfold in accelerated mode. In the process of
            creating her works, she strives to replicate this logic—scaling tiny
            particles into transcendental, unimaginable images. Her craft and
            practice here are to co-tune the equipment, awakening the
            sensitivity of the mechanical eye to processes that are carefully
            hidden from direct human perception.
          </p>
          {/* <p>
            Lena was born into a family where her father and grandfather were
            artists. She was a kid with a pencil in her hands. At the age of 20,
            she started to delve into photography. For the next 16 years, she
            worked as a portrait photographer. She adored analog cameras, and
            since 2014, she mostly worked with them. Lena’s father suddenly
            passed away in 2020, and she decided to fulfill her dream of
            becoming an artist like her father and grandfather were. Stepping
            away from working with models, she began creating abstract macro
            photography using a wide range of materials and techniques. The
            process includes not only the objects in the images but also a
            complex variety of skills, working with light, physical filters, and
            composition. By using her imagination, she creates extraterrestrial
            images that transport you far away from Earth, showing the
            magnificence of our universe. By exploring her pictures, you will
            discover a fusion of the familiar and the alien, revealing the
            fullness of our understanding and beyond, where hidden, mysterious,
            and sometimes unnerving phenomena of our cosmos come to light.
            Adding painting on canvas and textile artworks to her work, she
            doesn’t limit herself in creativity. She believes that the only way
            to be free of the countless images that appear in her mind is to
            bring them to life.
          </p> */}
          <div className="containerLink">
            <a
              href={`https://lenaleiko.com${cv}`}
              target="_blank"
              className="prc"
              rel="noreferrer">
              CV
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
