import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, withRouter } from 'react-router-dom';
import Routes from 'src/routes';

import theme from 'src/theme';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}
 
const WithRoutered = withRouter(ScrollToTop);


export default () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <WithRoutered>
        <Routes />
      </WithRoutered>
    </BrowserRouter>
  </ThemeProvider>
);


// "/hellen-website/docs"
// "/"
