import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { wrapRouteIntoLayout } from 'src/routes/helpers';

import appRoutes from './app/routes';
import AppLayout from './app/Layout';

export const routes = []
  .concat(appRoutes('').map(wrapRouteIntoLayout(AppLayout)));

export default () => (
  <Switch>
    {
      routes.map((props, index) => <Route key={index} {...props} />)
    }
    
  </Switch>
);
