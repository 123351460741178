import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Origin from 'src/static/Origin/1.png';
import Layers from 'src/static/Layers/1.png';
import Irreversibility from 'src/static/Irreversibility/3p.jpg';
import icoStack from 'src/static/AboutPics/icoStack.png';

import './index.css';

export default () => {
  React.useEffect(() => {
    document.title = 'Lena Leiko Projects';
  }, []);

  return (
    <div className="wrapper-pho">
      <Link to="/albums/Layers" className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <img className="itemPhoto" src={Layers} alt="Layers" />
        <div className="labelPhoto">{'Layers'}</div>
      </Link>
      <Link to="/albums/Irreversibility" className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <img
          className="itemPhoto"
          src={Irreversibility}
          alt="Irreversibility"
        />
        <div className="labelPhoto">Irreversibility</div>
      </Link>
      {/* <Link to="/albums/Origin" className="itemPhotoWrapper">
      <img className="itemPhoto" src={Origin} alt="Origin" />
      <div className="labelPhoto">{'Origin'}</div>
    </Link> */}
    </div>
  );
};
