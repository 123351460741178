import { withRouter } from 'react-router-dom';
import { withProps } from 'recompose';
import { compose } from 'redux';
import AlbumsJSON from 'src/albums.json';

import Album from './Album';

export default compose(
  withRouter,
  withProps(props => {
    const album = AlbumsJSON.albums.find(
      e => e.name === props.match.params.name,
    );

    return {
      ...props,
      album,
    };
  }),
)(Album);
