import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './styles';

const Container = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default styled(Container)`${styles}`;
