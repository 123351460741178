import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: 300px) {
    .toTop {
      position: fixed;
      right: 27px;
      bottom: 15px;

      width: 45px;
      height: 45px;
    }
    .imageUp {
      width: 45px;
      height: 45px;
    }
  }
  @media (min-width: 600px) {
    .toTop {
      position: fixed;
      right: 40px;
      bottom: 20px;

      width: 45px;
      height: 45px;
    }
    .imageUp {
      width: 45px;
      height: 45px;
    }
  }
`;
