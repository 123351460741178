import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import image1 from 'src/static/1/1p.jpg';
import image2 from 'src/static/2/4p.jpg';
import image3 from 'src/static/3/5p.jpg';
import image4 from 'src/static/4/6p.jpg';
import image5 from 'src/static/5/7p.jpg';
import image6 from 'src/static/6/8p.jpg';
import image7 from 'src/static/7/10p.jpg';
import image8 from 'src/static/8/12p.jpg';
import image9 from 'src/static/9/13p.jpg';
import image10 from 'src/static/10/15p.jpg';
import image11 from 'src/static/11/16p.jpg';
import image12 from 'src/static/12/19p.jpg';
import image13 from 'src/static/13/20p.jpg';
import image14 from 'src/static/14/14p.jpg';
import image15 from 'src/static/15/11p.jpg';
import image16 from 'src/static/16/16p.jpg';
import image17 from 'src/static/17/17p.jpg';
import image18 from 'src/static/18/18p.jpg';
import image19 from 'src/static/19/22p.jpg';
import image20 from 'src/static/20/25p.jpg';
import image21 from 'src/static/21/28p.jpg';
import image22 from 'src/static/22/32p.jpg';
import image23 from 'src/static/23/34p.jpg';
import image24 from 'src/static/24/36p.jpg';
import image25 from 'src/static/25/38p.jpg';
import image26 from 'src/static/26/40p.jpg';
import image27 from 'src/static/27/42p.jpg';
import image28 from 'src/static/28/44p.jpg';
import image29 from 'src/static/29/46p.jpg';
import image30 from 'src/static/30/48p.jpg';
import image31 from 'src/static/31/50p.jpg';
import image32 from 'src/static/32/53p.jpg';
import image33 from 'src/static/33/55p.jpg';
import image34 from 'src/static/34/56p.jpg';
import image35 from 'src/static/35/58p.jpg';
import image36 from 'src/static/36/60p.jpg';
import image37 from 'src/static/37/62p.jpg';
import image38 from 'src/static/38/64p.jpg';
import image39 from 'src/static/39/66p.jpg';
import image40 from 'src/static/40/68p.jpg';
import image41 from 'src/static/41/70a.jpg';
import image42 from 'src/static/42/72a.jpg';
import image44 from 'src/static/44/76a.jpg';
import image45 from 'src/static/45/78a.jpg';
import image46 from 'src/static/46/80a.jpg';
import image47 from 'src/static/47/82a.jpg';
import image48 from 'src/static/48/84a.jpg';
import image49 from 'src/static/49/86a.jpg';
import image50 from 'src/static/50/88a.jpg';
import image51 from 'src/static/51/89a.jpg';
import image52 from 'src/static/52/91a.jpg';
import icoStack from 'src/static/AboutPics/icoStack.png';

import './index.css';

const RequestPrice = img => (
  <a
    href={`mailto:lenaleikko@gmail.com?subject=${'Photographs'}&body=Hello, I would like to ask about the price of the painting.
    \n\n https://lenaleiko.com${img}`}
    className="prc">
    request details
  </a>
);

export default () => {
  React.useEffect(() => {
    document.title = 'Lena Leiko Photographs';
  }, []);

  return (
    <div className="containerPhots">
      <div className="wrapperPhots">
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/41">
            <img className="itemPhoto" src={image41} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image41)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/42">
            <img className="itemPhoto" src={image42} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image42)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/43">
            <img className="itemPhoto" src={image27} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image27)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/44">
            <img className="itemPhoto" src={image44} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image44)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/45">
            <img className="itemPhoto" src={image45} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image45)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/46">
            <img className="itemPhoto" src={image46} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image46)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/47">
            <img className="itemPhoto" src={image47} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image47)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/48">
            <img className="itemPhoto" src={image48} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image48)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/49">
            <img className="itemPhoto" src={image49} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image49)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/52">
            <img className="itemPhoto" src={image52} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image52)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/51">
            <img className="itemPhoto" src={image51} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image51)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/50">
            <img className="itemPhoto" src={image50} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image50)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>

        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/28">
            <img className="itemPhoto" src={image28} alt="1" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image28)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/29">
            <img className="itemPhoto" src={image29} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image29)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/11">
            <img className="itemPhoto" src={image11} alt="11" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>70x50</div>
              <div className="rowPrice">{RequestPrice(image11)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/16">
            <img className="itemPhoto" src={image16} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image16)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/40">
            <img className="itemPhoto" src={image40} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image40)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/39">
            <img className="itemPhoto" src={image39} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image39)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/32">
            <img className="itemPhoto" src={image32} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image32)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/30">
            <img className="itemPhoto" src={image30} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image30)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/33">
            <img className="itemPhoto" src={image33} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image33)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div to="/albums/1" className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/1">
            <img className="itemPhoto" src={image1} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>70x50</div>
              <div className="rowPrice">{RequestPrice(image1)}</div>
            </div>
            <div className="paintinfo">300gr smooth fine art paper</div>
            <div className="paintinfo">{`Museum glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/10">
            <img className="itemPhoto" src={image10} alt="10" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>50x50</div>
              <div className="rowPrice">{RequestPrice(image10)}</div>
            </div>
            <div className="paintinfo">300gr structured fine art paper</div>
            <div className="paintinfo">{`Museum glass`}</div>
            <div className="paintinfo">{`Aluminum frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/15">
            <img className="itemPhoto" src={image15} alt="7" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>50x50</div>
              <div className="rowPrice">{RequestPrice(image15)}</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="statusContainer">
              <div className="rowPhotoInfo">Unique piece</div>
            </div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/5">
            <img className="itemPhoto" src={image5} alt="5" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>70x50</div>
              <div className="rowPrice">{RequestPrice(image5)}</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>

        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/7">
            <img className="itemPhoto" src={image7} alt="7" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>50x50</div>
              <div className="rowPrice">out of stock</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="statusContainer">
              {/* <div className="statusLabel">{`out of stock`}</div> */}
            </div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/6">
            <img className="itemPhoto" src={image6} alt="6" />
          </Link>

          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image6)}</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/3">
          <img className="itemPhoto" src={image3} alt="3" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>60x40</div>
            <div className="rowPrice">{RequestPrice(image3)}</div>
          </div>
          <div className="paintinfo">300gr structured fine art paper</div>
          <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/4">
            <img className="itemPhoto" src={image4} alt="4" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>60x40</div>
              <div className="rowPrice">{RequestPrice(image4)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            <div className="paintinfo">{`Museum glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/34">
            <img className="itemPhoto" src={image34} alt="4" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>60x40</div>
              {/* <div className="rowPrice">{RequestPrice(image34)}</div> */}
              <div className="rowPrice">out of stock</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            <div className="paintinfo">{`Museum glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/31">
          <img className="itemPhoto" src={image31} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image31)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/35">
            <img className="itemPhoto" src={image35} alt="1" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image35)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/19">
            <img className="itemPhoto" src={image19} alt="1" />
          </Link>
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image19)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            {/* <div className="paintinfo">{`Museum glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div> */}
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>

        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/9">
            <img className="itemPhoto" src={image9} alt="9" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image9)}</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/2">
            <img className="itemPhoto" src={image2} alt="2" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image2)}</div>
            </div>
            <div className="paintinfo">gallery lustre matte</div>
            <div className="paintinfo">{`Mat glass`}</div>
            <div className="paintinfo">{`Wooden frame`}</div>
            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/36">
          <img className="itemPhoto" src={image36} alt="9" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image36)}</div>
          </div>
          <div className="paintinfo">320gr gallery lustre</div>
          <div className="paintinfo">{`Mat glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/37">
            <img className="itemPhoto" src={image37} alt="9" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image37)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            <div className="paintinfo">{`Mat glass`}</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>
        <div className="itemPhotoWrapper">
          <div className="stackIconWrapper">
            <img className="stackIcon" src={icoStack} alt="1" />
          </div>
          <Link to="/albums/38">
            <img className="itemPhoto" src={image38} alt="9" />
          </Link>
          {/* <div className="labelPhoto">{'Layers'}</div> */}
          <div className="paintinfocontainer">
            <div className="paintinfoHeader">
              <div>size on request</div>
              <div className="rowPrice">{RequestPrice(image38)}</div>
            </div>
            <div className="paintinfo">fine art giclée print</div>
            <div className="paintinfo">{`Mat glass`}</div>

            <div className="rowPhotoInfo">Unique piece</div>
          </div>
        </div>

        {/* OLD HIDDEN ALBUMS */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/26">
          <img className="itemPhoto" src={image26} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image26)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/25">
          <img className="itemPhoto" src={image25} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image25)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div to="/albums/24" className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/24">
          <img className="itemPhoto" src={image24} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image24)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/23">
          <img className="itemPhoto" src={image23} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image23)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/22">
          <img className="itemPhoto" src={image22} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image22)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/21">
          <img className="itemPhoto" src={image21} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image21)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/18">
          <img className="itemPhoto" src={image18} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image18)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/17">
          <img className="itemPhoto" src={image17} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image17)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/20">
          <img className="itemPhoto" src={image20} alt="1" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>size on request</div>
            <div className="rowPrice">{RequestPrice(image20)}</div>
          </div>
          <div className="paintinfo">fine art giclée print</div>

          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/8">
          <img className="itemPhoto" src={image8} alt="8" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>40x40</div>
            <div className="rowPrice">{RequestPrice(image8)}</div>
          </div>
          <div className="paintinfo">320gr gallery lustre</div>
          <div className="paintinfo">{`Mat glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/14">
          <img className="itemPhoto" src={image14} alt="9" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>50x40</div>
            <div className="rowPrice">{RequestPrice(image14)}</div>
          </div>
          <div className="paintinfo">320gr gallery lustre</div>
          <div className="paintinfo">{`Mat glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/12">
          <img className="itemPhoto" src={image12} alt="12" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>60x80</div>
            <div className="rowPrice">{RequestPrice(image12)}</div>
          </div>
          <div className="paintinfo">320gr gallery lustre</div>
          <div className="paintinfo">{`Mat glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}
        {/* <div className="itemPhotoWrapper">
        <div className="stackIconWrapper">
          <img className="stackIcon" src={icoStack} alt="1" />
        </div>
        <Link to="/albums/13">
          <img className="itemPhoto" src={image13} alt="13" />
        </Link>

        <div className="paintinfocontainer">
          <div className="paintinfoHeader">
            <div>70x50</div>
            <div className="rowPrice">{RequestPrice(image13)}</div>
          </div>
          <div className="paintinfo">320gr gallery lustre</div>
          <div className="paintinfo">{`Mat glass`}</div>
          <div className="paintinfo">{`Wooden frame`}</div>
          <div className="rowPhotoInfo">Unique piece</div>
        </div>
      </div> */}

        {/* <Link to="/albums/Origin" className="itemPhotoWrapper">
      <img className="itemPhoto" src={Origin} alt="Origin" />
      <div className="labelPhoto">{'Origin'}</div>
    </Link> */}
      </div>
    </div>
  );
};
