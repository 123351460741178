import { css } from 'styled-components';

const abs = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default css`
  display: grid;
  flex-wrap: wrap;
  grid-gap: 6px;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  grid-template-rows: minmax(1fr, auto);
  grid-auto-flow: dense;

  & .albumItem {
    position: relative;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-bottom: 100%;
    background: white;
  }

  @media screen and (min-width: 300px) {
    & .albumItem {
      min-width: 95%;
      max-width: 110%;
      max-height: 80%;
      padding-bottom: 150%;
    }
  }

  @media screen and (min-width: 1200px) {
    & .albumItem {
      /* min-width: 75%;
      max-width: 90%;
      max-height: 90%;
      padding-bottom: 150%; */
    }
  }

  @media screen and (min-width: 1950px) {
    & .albumItem {
      /* min-width: 75%;
      max-width: 90%;
      max-height: 100%; */
    }
  }
  & .imgWrap {
    ${abs}
    position: relative;
    padding-bottom: 5%;
  }

  & img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  & .infoWrap {
    ${abs}
    opacity: 0;

    transition: opacity 0.3s;
    cursor: pointer;
  }
  & .infoWrap:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.5);
  }
  & .loading {
    display: flex;

    justify-content: center;
  }
  & .infoLink {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 95%;
    width: 75%;
    font-size: 30px;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 0 0 100px white;
    text-transform: uppercase;
  }
`;
