import { css } from 'styled-components';

export default css`
  //   flex: auto 0 0;

  //   & .headerContent {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     flex-wrap: wrap-reverse;
  //     padding: 10px 0;
  //     margin-left: 20px;
  //   }
  //   & .headerMain {
  //     font-size: 30px;
  //     margin-left 30px;
  //     pointer-events: all;
  //   }

  //   @media (max-width: 450px) {
  //   .headerMain {
  //     font-size: 25px;
  //     margin-left 5px;
  //     pointer-events: all;
  //     background-color: red;
  //   }
  // }
`;
