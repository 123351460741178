import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default class Popup extends Component {
  static propTypes = {
    images: PropTypes.array,
    isOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    currentIdx: PropTypes.number, // eslint-disable-line
  };

  state = {
    currentIdx: 0,
  };

  static getDerivedStateFromProps(props, state) {
    return {
      currentIdx: state.currentIdx || props.currentIdx,
    };
  }

  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyUp = evt => {
    switch (evt.keyCode) {
      case 37:
        return this.goPrev();
      case 39:
        return this.goNext();
      case 27:
        return this.props.closePopup();
      default:
    }
  };

  goPrev = () =>
    this.setState(prevState => {
      const newIdx = prevState.currentIdx - 1;

      return {
        currentIdx: newIdx > 0 ? newIdx : 0,
      };
    });

  goNext = () =>
    this.setState(prevState => {
      const { images } = this.props;
      const newIdx = prevState.currentIdx + 1;

      return {
        currentIdx: images.length <= newIdx ? images.length - 1 : newIdx,
      };
    });

  render() {
    const { isOpen, images, closePopup, photoinfo } = this.props;
    const { currentIdx } = this.state;

    if (!isOpen) return null;

    const image = images[currentIdx];

    return (
      <div className="containerPhoto">
        <div className={photoinfo ? 'popupWrapPhoto' : 'popupWrap'}>
          <button
            className={photoinfo ? 'buttonPrevPhoto' : 'buttonPrev'}
            disabled={currentIdx === 0}
            type="button"
            onClick={this.goPrev}>
            <span>←</span>
          </button>
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <img alt="img" src={require(`src/static/${image}`)} />{' '}
            {photoinfo ? (
              <div className="photoInfo">{photoinfo[image]}</div>
            ) : null}
            {/* eslint-disable-line */}
          </div>
          <button
            className={photoinfo ? 'buttonNextPhoto' : 'buttonNext'}
            disabled={currentIdx === images.length - 1}
            type="button"
            onClick={this.goNext}>
            <span>→</span>
          </button>
        </div>
        <button className="buttonClose" type="button" onClick={closePopup}>
          ✕
        </button>
        <div className="navigation" />
      </div>
    );
  }
}
