import React from 'react';

export const initRoutes = (...routes) => (root = '') => routes.reduce((readyRoutes, route) => readyRoutes.concat(route(root)), []);

export const wrapRouteIntoLayout = Layout => ({ component, render, ...routeProps }) => {
  const RouteComponent = render || component;

  return {
    ...routeProps,
    render: props => (
      <Layout>
        <RouteComponent {...props} />
      </Layout>
    ),
  };
};
